import React, { useCallback, useMemo, useState } from "react";
import { PropTypes } from "prop-types";
import { useReactFlowContext } from "src/contexts/reactFlowContext";
import NodeVisualizer from "../../../../components/NodeVisualizer";
import { getNodesAndEdgesFromObjectives, getStrategyLayout } from "./utils";

const StrategyVisualizer = ({ objectives }) => {
  const context = useReactFlowContext();
  const [isFitToView, setIsFitToView] = useState(false);
  const { edges = [], nodes = [] } = useMemo(
    () => getNodesAndEdgesFromObjectives(objectives),
    [objectives]
  );

  const afterLayout = useCallback(
    ({ reactFlow, nodes: positionedNodes }) => {
      if (isFitToView) {
        return;
      }

      const paneRects = document
        ?.querySelector(".react-flow__pane")
        ?.getBoundingClientRect();
      let offset = 100;
      const teamNode = positionedNodes.find(
        (node) => node.data.nodeType === "team"
      );

      if (teamNode) {
        offset = teamNode.width - 50;
      }

      setIsFitToView(true);
      reactFlow.setViewport({ x: paneRects.width - offset, y: 100, zoom: 0.8 });
    },
    [isFitToView]
  );

  return (
    <NodeVisualizer
      loading={!objectives}
      initialNodes={nodes}
      initialEdges={edges}
      getLayout={getStrategyLayout}
      viewType="strategy"
      showMiniMap
      afterLayout={afterLayout}
      isFullScreen={context?.isFullScreen}
      toggleFullScreen={context?.toggleFullScreen}
      shouldUseRemoteControl
    />
  );
};

StrategyVisualizer.propTypes = { objectives: PropTypes.array.isRequired };
export default StrategyVisualizer;
