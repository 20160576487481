import React, { useCallback, useState } from "react";
import PersonPropType from "src/custom-prop-types/person";
import { useReactFlowContext } from "src/contexts/reactFlowContext";
import FloatingControl from "src/components/TeamInfo/components/FloatingControl";
import NodeVisualizer from "../../../../components/NodeVisualizer";
import useGetPersonHierarchyNodes from "./useGetPersonHierarchyNodes";

const PersonHierarchyChart = ({ person }) => {
  const context = useReactFlowContext();
  const { nodes, edges, loading, onExpandManagingClick } =
    useGetPersonHierarchyNodes(person);

  const [initialFitViewTriggered, setInitialFitViewTriggered] = useState(false);

  const afterLayout = useCallback(
    ({ reactFlow, nodes: layoutedNodes }) => {
      if (initialFitViewTriggered) {
        return;
      }

      const currentPersonNode = layoutedNodes.find(
        (node) => node.data.aggregateId === person.aggregateId
      );
      const managerPersonNode = layoutedNodes.find(
        (node) => node.data.aggregateId === person?.managedBy?.aggregateId
      );

      const nodesToZoom = [currentPersonNode];

      if (managerPersonNode) {
        nodesToZoom.push(managerPersonNode);
      }

      setInitialFitViewTriggered(true);
      reactFlow.fitView({ nodes: nodesToZoom });
      reactFlow.zoomTo(0.8);
    },
    [person, initialFitViewTriggered]
  );

  return (
    <>
      <NodeVisualizer
        key={person?.aggregateId}
        title="Reporting Line"
        loading={loading || !person}
        initialNodes={nodes}
        initialEdges={edges}
        afterLayout={afterLayout}
        onExpandManagingClick={onExpandManagingClick}
        noWheelZoom
        animatedNodesEnabled
        isFullScreen={context?.isFullScreen}
        toggleFullScreen={context?.toggleFullScreen}
        shouldUseRemoteControl
      />
      <FloatingControl />
    </>
  );
};

PersonHierarchyChart.propTypes = {
  person: PersonPropType,
};

export default PersonHierarchyChart;
